/** @module components/ErrorSplashPage */
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { appPaths } from 'utilities/routes';
import { supportDL } from 'utilities/constants';
import SplashPage from '../SplashPage';
import { ErrorType, Props } from './types';

/**
 * Displays error message
 * @param props The props
 * @return A JSX Element
 */
export default function ErrorSplashPage(props: Props): JSX.Element {
  const { errorType, me } = props;
  const { t } = useTranslation();
  let title: string;
  let message: string | JSX.Element;
  let referenceError: string;
  if (errorType === ErrorType.ALREADY_EXIST) {
    title = t('ErrorSplashPage.title.alreadyExist', 'Account already exists');
    message = (
      <>
        <Trans i18nKey="ErrorSplashPage.message.alreadyExist1">
          There is already a B2B account associated with
        </Trans>
        {' '}
        {me.user && me.user.email}
        {'. '}
        <Trans i18nKey="ErrorSplashPage.message.alreadyExist2">
          Please sign in using your existing B2B account in order to access REDshare.
        </Trans>
      </>
    );
    referenceError = t('ErrorSplashPage.referenceError.Conflict409', 'CONFLICT 409');
  } else {
    title = t('ErrorSplashPage.referenceError.blank', '');
    message = t('ErrorSplashPage.referenceError.blank', '');
    referenceError = t('ErrorSplashPage.referenceError.blank', '');
  }

  return (
    <SplashPage className="ErrorSplashPage">
      <h1 data-test-id="title">
        {title}
      </h1>
      <h4 data-test-id="message">
        {message}
      </h4>
      <h4>
        {errorType === ErrorType.ALREADY_EXIST && (
          <Trans i18nKey="ErrorSplashPage.message.registered">
            If you are unaware of an existing account or encounter further problems registering,
            {' '}
            please contact
            {' '}
            <a href={`mailto:${supportDL}`} className="link">Support</a>
            .
          </Trans>
        )}
        {errorType !== ErrorType.ALREADY_EXIST && (
          <>
            <Trans i18nKey="ErrorSplashPage.message.support1">
              If you need additional help, please contact
              {' '}
              <a href={`mailto:${supportDL}`} className="link">REDshare support</a>
              . Please reference error
              {' '}
            </Trans>
            {referenceError}
            <Trans i18nKey="ErrorSplashPage.message.support2">
              , and include the email address you are trying to register with.
            </Trans>
          </>
        )}
      </h4>
    </SplashPage>
  );
}
