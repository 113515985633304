/** @module components/ErrorSplashPage */
import { connect } from 'react-redux';
import { GlobalState } from 'store/types';
import ErrorSplashPage from './component';
import { StateProps } from './types';

export const mapStateToProps = (state: GlobalState): StateProps => ({
  me: state.me,
});

export default connect(
  mapStateToProps,
)(ErrorSplashPage);
