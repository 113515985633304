/** @module components/ErrorSplashPage */
import { Me } from 'services/metadata';

export enum ErrorType {
  ALREADY_EXIST = 'ALREADY_EXIST',
}

export interface OwnProps {
  errorType?: ErrorType;
}

export interface StateProps {
  me: Me;
}

export type Props = OwnProps & StateProps;
